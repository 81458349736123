@import url("https://fonts.googleapis.com/css?family=Montserrat:900");

:root {
    --c1: rgb(37.409033877038894, 33.91091593475533, 8.659974905897114);
    --c2: rgb(101.87586206896552, 82.06436781609196, 10.735632183908047);
    --c3: rgb(6.067759078830823, 15.555358724534987, 12.204162976085032);
    --c4: rgb(42.76600985221675, 174.89408866995075, 149.13546798029557);
    --c5: rgb(147.71782178217822, 115.21782178217822, 9.623762376237623);
    --c55: rgba(148, 116, 10, 0.183);
    --c6: rgb(15.763124199743919, 66.70486555697823, 54.88028169014085);
    --c7: rgb(19.191163976210706, 117.50382327952421, 98.20645709430757);
    --c75: rgba(19, 118, 98, 0.296);
    --c8: rgb(67.43333333333334, 55.142857142857146, 8.417460317460318);
}

.tag {
    
    text-align: center;
    display: inline-block;
    align-content: center;
    vertical-align: middle;
    /* margin-top: -3%; */
    margin-bottom: 0dvh;
    font-family: "Montserrat", ;
    font-size:40px !important;
    font-style: italic;
    letter-spacing: 0dvw !important;
    /* position: relative; */
    color: var(--c6);
}

.homepage {
    /* padding-top:2dvh; */
    text-align: center;
    align-content: center;
    width: 100dvw;
    margin-top: 0%;
    height:100dvh;
}


.doppelganger {
    
    margin-top:25dvh;
    min-width:100dvw;
    height:16dvw;
    padding:0;
    display: inline-block;
    font-family: "Montserrat", sans-serif;
    font-size: 10dvw;
    letter-spacing: 2dvw;
    position: relative;
    align-content: center;
    /* transform: translateX(-50%); */
    color: white;
    background: url("https://as1.ftcdn.net/v2/jpg/04/19/89/60/1000_F_419896028_3WaAOuzWwTube08UJAO8kNlDOw6eUlUN.jpg");
    background-size: auto 300%;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 80s linear infinite;
}

.letters {
    font-size: 7rem;
    letter-spacing: .5dvw;
    text-shadow: 56px 32px 11px rgba(0, 0, 0, 0.16);
    margin: 0!important;
}

@keyframes materialize {
    from {
        transform: scale(.7);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}


@keyframes shine {
    from {
        background-position: center 0;
    }
    to {
        background-position: center 200%;
    }
}








@keyframes materialize {
    from {
        transform: scale(.7);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}