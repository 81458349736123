.about-page {
    display: flex; /* Enables flexbox */
    justify-content: space-around; /* Arranges team member cards with equal space around them */
    flex-wrap: wrap; /* Allows the flex items to wrap onto multiple lines if needed */
}

.team-member-container {
    justify-content: space-around;
    height: 40vh;
    width: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;

    margin: 0em; /* Adds a margin around each team member card for better separation */
}

.team-member-image-container {
    height: 20vh;
    width: 20vh;
    display: flex;
    border-radius: 50%;
    margin: 1vh;
}

.team-member-image-container img {
    border-radius: 50%;
}
