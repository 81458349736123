.about-page .about-container {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    align-items: center;
    justify-content: space-around!important;
    height: 100%;
    width: 100%;
}

