.chat-window-container {
    height: 50vh;
    width: 35vh;
    display: flex;
    flex-direction: column;
    border: red solid ;
    position: relative;

}

.chat-header {
    width: 35vh;
    height: 5vh;
    border: blue solid ;
}
.chat-footer {
    width: 35vh;
    height: 5vh;
    border: purple solid;
}

.chat-log-item-header {
    display: flex;
    flex-direction: row;
    width: 35vh;
    height: 10vh;
    border: yellow solid
}

.message-box-container {
    display: flex;
    flex-direction: row;
    width: 35vh;
    height: 5vh;
    border: green solid;
}
